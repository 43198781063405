import { Button, Card, Text, Box, InlineGrid, Tooltip, InlineStack, Icon } from '@shopify/polaris'
import { currencyFormatter } from '../../utils/formatter'
import { ArrowRightIcon, InfoIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

export const InventoryCard = ({ title, totalSellPrice, description, tone, totalProducts, onCardClick }) => {
  const { t } = useTranslation()
  return (
    <Card title={title} sectioned padding='500'>
        <Box padding='200' minHeight="40px">
          <Tooltip content={t('inventory_card.loss_sales_tooltip')}>
            <InlineStack wrap={false}>
              <Text variant='headingLg' fontWeight='bold'>
                {(totalSellPrice && currencyFormatter.format(totalSellPrice))}
              </Text>
              <Box icon="InfoIcon" width="16px" visuallyHidden={!totalSellPrice}><Icon source={InfoIcon} tone="info"/></Box>
            </InlineStack>
          </Tooltip>
      </Box>
      <InlineGrid columns={['twoThirds', 'oneThird']}>
        <Box padding='200'>
          <Text as='h4' variation='subdued'>{description}</Text>
          <Text variant='headingXs' fontWeight='normal' tone={tone}>
            &#9632; {totalProducts} products
          </Text>
        </Box>
        <Button
          variant='primary'
          icon={ArrowRightIcon}
          onClick={onCardClick}
          disabled={totalProducts === 0}
        />
      </InlineGrid>
    </Card>
  )
}
