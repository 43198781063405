import React from 'react'
import { Page, Card } from '@shopify/polaris'

export const WelcomeView = () => {
  return (
    <Page
      hasSubtitleMaxWidth
      title='Welcome to Obius'
    >
      <Card sectioned>
      </Card>
    </Page>
  )
}
