import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '../../../context/context'
import { fetchBomsList } from '../../../shared/services/bomService'

export const useBomsList = (limit = 20) => {
  const [boms, setBoms] = useState([])
  const [filteredBoms, setFilteredBoms] = useState([])
  const [currentPageBoms, setCurrentPageBoms] = useState([])
  const [totalBoms, setTotalBoms] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [queryValue, setQueryValue] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPreviousPage, setHasPreviousPage] = useState(false)
  const { companyId, appBridge, isAppBridgeReady } = useContext(DataUpdateContext)

  const fetchBomsData = useCallback(async () => {
    if (!companyId || !isAppBridgeReady) return
    const offset = 0
    setIsLoading(true)
    setError(null)
    try {
      const data = await fetchBomsList(companyId, 10000, offset, appBridge)
      setBoms(data.boms)
      setFilteredBoms(data.boms)
      setTotalBoms(data.total)
      setCurrentPageBoms(data.boms.slice(offset, offset + limit))
      setHasNextPage(0 + limit < data.total)
      setHasPreviousPage(offset > 0)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [companyId, appBridge, isAppBridgeReady, limit])

  useEffect(() => {
    fetchBomsData()
  }, [fetchBomsData])

  const handleNextPage = useCallback(() => {
    const newOffset = Math.min(offset + limit, totalBoms)
    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalBoms)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageBoms(filteredBoms.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalBoms, filteredBoms])

  const handlePreviousPage = useCallback(() => {
    const newOffset = Math.max(0, offset - limit)
    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalBoms)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageBoms(filteredBoms.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalBoms, filteredBoms])

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value)
    setOffset(0)
    const newFilteredBoms = boms.filter((bom) =>
      bom.output_product.product_name?.toLowerCase().includes(value.toLowerCase()) ||
      bom.output_product.product_variant_name?.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredBoms(newFilteredBoms)
    setTotalBoms(newFilteredBoms.length)
    setCurrentPageBoms(newFilteredBoms.slice(0, limit))
    setHasNextPage(limit < newFilteredBoms.length)
    setHasPreviousPage(false)
  }, [boms, limit])

  return {
    boms,
    filteredBoms,
    currentPageBoms,
    totalBoms,
    isLoading,
    error,
    offset,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue
  }
}
