import { createContext, useEffect, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { backendRequest } from '../utils/axiosInstance'
import createApp from '@shopify/app-bridge'
// import { getSessionToken } from '@shopify/app-bridge/utilities'
import { createMenu } from '../utils/shopify'
export const DataUpdateContext = createContext()

export const DataUpdateProvider = ({ children }) => {
  const [lastUpdate, setLastUpdate] = useState(null)
  const [steps, setSteps] = useState(null)
  const [jobStatus, setJobStatus] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [isAppBridgeReady, setIsAppBridgeReady] = useState(false)
  const [companyParams, setCompanyParams] = useState(null)
  // const [sessionToken, setSessionToken] = useState(null)
  const [appBridge, setAppBridge] = useState(null)
  const [isLogin, setIsLogin] = useState(null)
  const [landingUrl, setLandingUrl] = useState(null)
  const [companyStatus, setCompanyStatus] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState('free')
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), [])
  const embedded = queryParams.get('embedded')
  const host = queryParams.get('host')
  const { t, i18n } = useTranslation()

  const getLandingUrl = ({ companyStatus, subscriptionStatus }) => {
    switch (process.env.REACT_APP_SHOPIFY_APP) {
      case 'bom':
        return '/bundles'
      default:
        if (subscriptionStatus !== 'active') return '/replenishment'
        if (companyStatus === 'onboarding' || companyStatus === 'new') return '/replenishment'
        if (companyStatus === 'active') return '/replenishment'
        return '/replenishment'
    }
  }

  useEffect(() => {
    if (embedded === '1') {
      const fetchLocale = async ({ appBridge }) => {
        const appState = await appBridge.getState()
        const userLanguage = appState?.staffMember?.locale || 'en-US'
        if (i18n.language !== userLanguage) i18n.changeLanguage(userLanguage)
      }
      if (!host) {
        console.error('Host must be provided for embedded mode.')
        return
      }
      const appBridgeInitial = createApp({
        apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
        host,
        embedded
      })
      setAppBridge(appBridgeInitial)
      fetchLocale({ appBridge: appBridgeInitial })
      createMenu({ appBridge: appBridgeInitial, t })
      setIsAppBridgeReady(true)
    } else {
      setIsAppBridgeReady(true)
    }
  }, [embedded, host, t, i18n])

  useEffect(() => {
    const login = async () => {
      if ((!embedded || !appBridge || !queryParams) && process.env.NODE_ENV !== 'development') return
      try {
        await backendRequest({ method: 'POST', url: '/auth/shopify-login', appBridge, data: { queryString: queryParams.toString() } })
        setIsLogin(true)
      } catch (error) {
        console.error('Error loging in', error)
        setIsLogin(false)
      }
      setIsLogin(true)
    }
    login()
  }, [embedded, appBridge, queryParams])

  useEffect(() => {
    const fetchMyCompany = async () => {
      if (embedded && !isLogin) return
      try {
        const { data: myCompany } = await backendRequest({ method: 'GET', url: '/obius/companies/my-company', appBridge })
        const companyStatus = myCompany.company_status
        setCompanyStatus(companyStatus)
        const companyId = myCompany.company_id
        const subscriptionStatus = myCompany.subscription_status
        const landingUrl = getLandingUrl({ companyStatus, subscriptionStatus })
        setLandingUrl(landingUrl)
        setSubscriptionPlan(myCompany.subscription_plan)
        const lastUpdate = myCompany.last_update
        setCompanyParams(myCompany.params || {})
        setCompanyId(companyId)
        if (lastUpdate) {
          setLastUpdate(lastUpdate)
        } else {
          const interval = setInterval(async () => {
            const { data: { jobs: [job] } } = await backendRequest({
              method: 'get',
              url: `/job/jobs?company_id=${companyId}&job_type=initial_end_to_end,end_to_end&limit=1`,
              appBridge
            })
            if (!job) return
            setSteps(job.steps)
            if (job.status === 'succeeded') {
              setLastUpdate(job.start_date)
              clearInterval(interval)
            }
            if (job.status === 'failed' && job.job_type === 'initial_end_to_end') {
              setJobStatus(job.status)
              clearInterval(interval)
            }
          }, 5000)
        }
      } catch (error) {
        console.error('Error fetching last succeeded job', error)
        setIsLogin(false)
      }
    }
    fetchMyCompany()
  }, [appBridge, isLogin, embedded])

  const updateSubscriptionPlan = (plan) => {
    setSubscriptionPlan(plan)
  }

  const setThresholdsRequestHeaders = useCallback((companyParams) => {
    const headers = {}
    if (companyParams.dailyVelocityThreshold !== undefined && companyParams.dailyVelocityThreshold !== null) headers['X-Daily-Velocity-Threshold'] = companyParams.dailyVelocityThreshold.toString()
    if (companyParams.replenishNowDayThreshold !== undefined && companyParams.replenishNowDayThreshold !== null) headers['X-Replenish-Now-Day-Threshold'] = companyParams.replenishNowDayThreshold.toString()
    if (companyParams.replenishSoonDayThreshold !== undefined && companyParams.replenishSoonDayThreshold !== null) headers['X-Replenish-Soon-Day-Threshold'] = companyParams.replenishSoonDayThreshold.toString()
    if (companyParams.deadstockDayThreshold !== undefined && companyParams.deadstockDayThreshold !== null) headers['X-Deadstock-Day-Threshold'] = companyParams.deadstockDayThreshold.toString()
    if (companyParams.overstockDayThreshold !== undefined && companyParams.overstockDayThreshold !== null) headers['X-Overstock-Day-Threshold'] = companyParams.overstockDayThreshold.toString()
    return headers
  }, [])

  return (
    <DataUpdateContext.Provider value={{
      lastUpdate,
      companyId,
      isLogin,
      companyStatus,
      appBridge,
      landingUrl,
      subscriptionPlan,
      steps,
      jobStatus,
      updateSubscriptionPlan,
      setThresholdsRequestHeaders,
      companyParams,
      isAppBridgeReady
    }}>
      {children}
    </DataUpdateContext.Provider>
  )
}
