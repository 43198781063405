import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import './i18n'
import store from './store'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { LicenseManager } from 'ag-grid-enterprise'
import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
import { registerServiceWorker } from './serviceWorkerRegistration'

let App
switch (process.env.REACT_APP_SHOPIFY_APP) {
  case 'bom':
    App = require('./App.bom').default
    break
  case 'replen':
    App = require('./App.replen').default
    break
  default:
    App = require('./App').default
}

async function enableMocking () {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser')
    return worker.start()
  }
}

enableMocking().then(() => {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)
  createRoot(document.getElementById('root')).render(
    <Provider store={store}>
      <AppProvider i18n={translations}>
        <App />
      </AppProvider>
    </Provider>
  )
})

if (process.env.NODE_ENV !== 'development') {
  registerServiceWorker()
}

reportWebVitals(console.log)
