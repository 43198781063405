import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '../../../context/context'
import { fetchBomDetails, fetchProducts, updateBom, deleteBom } from '../../../shared/services/bomService'
import { objectToId } from '../../../utils'

export const useBomDetails = (initialBomId, setIsSaving) => {
  const [bom, setBom] = useState({ materials: [] })
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [bomId, setBomId] = useState(initialBomId)
  const { companyId, appBridge, isAppBridgeReady } = useContext(DataUpdateContext)

  const fetchData = useCallback(async () => {
    const promises = []
    setIsLoading(true)
    setError(null)
    if (!bomId || !companyId || !isAppBridgeReady) return
    try {
      promises.push(fetchProducts(companyId, appBridge))
      if (bomId !== 'new') promises.push(fetchBomDetails(bomId, appBridge))
      const [productsData, bomData] = await Promise.all(promises)
      if (bomId !== 'new') setBom(bomData)
      setProducts(productsData)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [bomId, companyId, appBridge, isAppBridgeReady])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const prepareBomDataToSave = useCallback(() => {
    return {
      bom_id: bom.bom_id,
      company_id: companyId,
      output_quantity: bom.output_product_quantity || 1,
      output_product_id: bom.output_product_id,
      output_product: {
        inventory_type: 'finished_product'
      },
      materials: bom.materials.map(material => ({
        company_id: companyId,
        material_id: material.material_id,
        product_id: material.product.product_id,
        quantity: +material.quantity,
        product: {
          inventory_type: 'material'
        }
      }))
    }
  }, [bom, companyId])

  const handleDelete = useCallback(async () => {
    setError(null)
    try {
      await deleteBom(bomId, appBridge)
      return true
    } catch (err) {
      setError(err.message)
      return false
    }
  }, [bomId, appBridge])

  const handleDeleteIfNeeded = useCallback(async (bomDataToSave) => {
    if (bomDataToSave.bom_id !== bomId && bomId !== 'new') {
      await handleDelete(bomId)
    }
  }, [handleDelete, bomId])

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    setError(null)
    try {
      const bomDataToSave = prepareBomDataToSave()
      await handleDeleteIfNeeded(bomDataToSave)
      const updatedBom = await updateBom(bomDataToSave, appBridge)
      setBomId(updatedBom.bom_id)
      return updatedBom.bom_id
    } catch (err) {
      setError(err.message)
    } finally {
      setIsSaving(false)
    }
  }, [appBridge, handleDeleteIfNeeded, prepareBomDataToSave, setBomId, setIsSaving])

  const handleQuantityChange = useCallback((materialId, newQuantity) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.map(m =>
        m.material_id === materialId ? { ...m, quantity: parseInt(newQuantity, 10) } : m
      )
    }))
  }, [])

  const handleOutputProductSave = useCallback(([newProduct]) => {
    const newBomId = objectToId({ outputProductId: newProduct.product_id })
    setBom(prevBom => ({
      ...prevBom,
      bom_id: newBomId,
      output_product_id: newProduct.product_id,
      output_product: {
        inventory_type: 'finished_product',
        product_name: newProduct.product_name,
        product_variant_name: newProduct.product_variant_name
      },
      materials: prevBom.materials.map(material => ({
        ...material,
        company_id: companyId,
        material_id: objectToId({ bomId: newBomId, productId: material.product.product_id })
      }))
    }))
  }, [companyId])

  const handleRemoveMaterial = useCallback((materialId) => {
    setBom(prevBom => ({
      ...prevBom,
      materials: prevBom.materials.filter(m => m.material_id !== materialId)
    }))
  }, [])

  const handleRemoveOutputProduct = useCallback(() => {
    setBom(prevBom => ({
      ...prevBom,
      bom_id: undefined,
      output_product_id: undefined,
      output_product: undefined
    }))
  }, [])

  const handleMaterialModalSave = useCallback((newMaterials) => {
    if (!companyId) return
    setBom(prevBom => ({
      ...prevBom,
      materials: [
        ...prevBom.materials,
        ...newMaterials.map(material => ({
          company_id: companyId,
          material_id: objectToId({ productId: material.product_id, bomId: prevBom.bom_id }),
          product: material,
          quantity: 1,
          product_id: material.product_id
        }))
      ]
    }))
  }, [companyId])

  return {
    bom,
    products,
    isLoading,
    error,
    handleSave,
    handleQuantityChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    handleRemoveMaterial,
    handleMaterialModalSave,
    handleDelete // Add this to the returned object
  }
}
