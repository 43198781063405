import { useState, useCallback } from 'react'
import { Card, Box, Text, Autocomplete, BlockStack, Icon, InlineStack, Button } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'

export const BundleProductCard = ({ bom, onSelectProduct, onRemoveOutputProduct }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectProduct(value)
      setInputValue('')
    }
  }, [onSelectProduct])

  const outputProduct = bom?.output_product

  return (
    <Card>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('bundles.bundle')}
        </Text>
        {!outputProduct && (
          <Autocomplete
            options={[]}
            selected={[]}
            onSelect={() => {}}
            textField={
              <Autocomplete.TextField
                onChange={handleInputChange}
                value={inputValue}
                placeholder={t('bundles.searchForProducts')}
                autoComplete="off"
                prefix={<Icon source={SearchIcon} />}
              />
            }
          />
        )}
      </BlockStack>
      {outputProduct && (
        <InlineStack align="space-between">
          <Box padding="200" borderColor="black">
            <Text variant="bodyMd" fontWeight="bold" as="h3">
              {outputProduct.product_name}
            </Text>
            <Text variant="bodySm" color="subdued">
              {outputProduct.product_variant_name}
            </Text>
          </Box>
          <Button
            onClick={() => onRemoveOutputProduct()}
            plain
            icon={XSmallIcon}
            variant="tertiary"
          >
          </Button>
        </InlineStack>
      )}
    </Card>
  )
}
