import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import DefaultLayout from './layouts/DefaultLayout'
import LoginView from './views/LoginView'
import DashboardView from './views/DashboardView'
import ReportView from './views/ReportView'
import InventoryHistoryView from './views/InventoryHistoryView'
import ReplenishmentView from './views/ReplenishmentView'
import ItemsView from './views/ItemsView'
import InventoryForecastView from './views/InventoryForecastView'
import SalesForecastView from './views/SalesForecastView'
import SalesAnalyticsView from './views/SalesAnalyticsView'
import SalesHistoryView from './views/SalesHistoryView'
import { SettingView } from './shopify/replen/views/SettingView'
import { BundleListView } from './shopify/bom/views/BundleListView'
import { BundleDetailsView } from './shopify/bom/views/BundleDetailsView'
import CollectionView from './views/CollectionView'
import './App.css'
import { DataUpdateProvider } from './context/context'
import ErrorPage from './components/common/ErrorPage'

const ProtectedRoute = ({ isAuthenticated, redirectPath = '/login' }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />
  }
  return <Outlet />
}
function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  let isAuthenticated = !!Cookies.get('authToken')
  if (process.env.NODE_ENV === 'development') isAuthenticated = true
  return (
    <Router>
      <Routes>
        <Route path='*' element={isAuthenticated ? <Navigate to='/dashboard' /> : <Navigate to='/login' />} />
        <Route path='/login' element={<LoginView />} />
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          <Route index element={<Navigate to='/dashboard' replace />} />
          <Route path='/dashboard' element={<DefaultLayout><DashboardView /></DefaultLayout>} />
          <Route path='/report' element={<DefaultLayout><ReportView /></DefaultLayout>} />
          <Route path='/collection' element={<DefaultLayout><CollectionView /></DefaultLayout>} />
          <Route path='/inventory' element={<DefaultLayout><InventoryHistoryView /></DefaultLayout>} />
          <Route path='/items' element={<DefaultLayout><ItemsView /></DefaultLayout>} />
          <Route path='/replenishment' element={<DefaultLayout><ReplenishmentView /></DefaultLayout>} />
          <Route path='/sales-forecast' element={<DefaultLayout><SalesForecastView /></DefaultLayout>} />
          <Route path='/inventory-forecast' element={<DefaultLayout><InventoryForecastView /></DefaultLayout>} />
          <Route path='/supply-forecast' element={<DefaultLayout><SalesAnalyticsView /></DefaultLayout>} />
          <Route path='/sales' element={<DefaultLayout><SalesHistoryView /></DefaultLayout>} />
          <Route path='/settings' element={<DefaultLayout><SettingView /></DefaultLayout>} />
          <Route path='/bundles' element={<DefaultLayout><BundleListView /></DefaultLayout>} />
          <Route path='/bundles/:id' element={<DefaultLayout><BundleDetailsView /></DefaultLayout>} />
          <Route path='/401' element={<ErrorPage errorCode='401' />} />
          <Route path='/404' element={<ErrorPage errorCode='404' />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
